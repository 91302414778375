/* Fade In Animation */
.fade-in {
  opacity: 0;
  animation: fadeInAnimation 2s ease forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Navbar Component CSS */
.navbar {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 14vh;
  font-size: 16px;
  font-weight: bolder;
  z-index: 999;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: #013671;
  transition: width .3s;
}

.nav-link:hover::after {
  width: 100%;
  transition: width .3s;
}

.nav-link.active {
  color: #013671;
}

.nav-link.active::after {
  width: 100%;
}

.logo {
  width: 14vh;
  height: 12vh;
}

.navbar-brand {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.brand-name {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  color: #bd2624;
}

.contactusbutton {
  background-color: #bd2624;
  color: white;
  padding: 10px 15px;
  border: none;
  font-weight: lighter;
}

.contactusbutton:hover {
  background-color: maroon;
  transition: transform 0.3s ease;
}

/* Home Component CSS */
.first {
  display: flex;
  background-color: #0452a7;
  padding-top: 20vh;
}

.application {
  max-width: fit-content;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 8vh;
  color: white;
  border: 1px solid white;
  background-color: #0452a7;
  border-top: none;
  border-radius: 30px;
  padding: 50px 0;
}

.intro {
  display: flex;
  flex-direction: row;
  gap: 120px;
  justify-content: left;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 180px;
  background-color: white;
}

.icon-container {
  background-color: #0452a7;
  height: 50vh;
  padding: 100px 0;
}

.intro2 {
  height: 100%;
  width: 100%;
  border-radius: 30px;
  border: 2px solid #d0dbdd;
  margin-right: 10vh;
  padding: 40px 40px;
}

.products {
  margin-top: 10vh;
  margin-left: 40px;
}

.application-header {
  color: white;
  margin-left: 75px;
}

h3 {
  margin-top: 2vh;
  font-size: 3vh;
}

.header1 {
  font-size: 60px;
  margin-left: 75px;
  color: white;
}

.brochure-link {
  text-decoration: none;
}


/* Footer Component CSS */
.footer {
  position: absolute;
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  gap: 3vh;
  justify-content: center;
  align-items: center;
  background-color: #031B34;
}

.details {
  display: flex;
  flex-direction: row;
  gap: 10vh;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5vh;
}

.icon {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon i {
  margin-right: 10px;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  font-size: 7vh;
  gap: 4vh;
  color: white;
  padding: 1vh 3vh;
}

.social-media-icons a {
  color: white;
}

/* Services Component CSS */
.service-container {
  padding: 20vh 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  height: 100%;
  background-color: #EEEEEE;
}

.contractor-container {
  padding: 20vh 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  height: 100%;
  background-color: #EEEEEE;
}

.service-card {
  width: 40rem;
  height: 30rem;
}

.card-img-top {
  aspect-ratio: 3/2;
}

.contractor-img {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}


/* Contact us Component CSS */
.form-container {
  margin: 0 auto;
  background-color: white;
  padding: 10vh 20vh;
  width: 60%;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.5);
}

.contact-container {
  position: relative;
  padding: 17vh 0;
  background-color: #0452a7;
}

.form-control:focus,
.form-check-input:focus {
  box-shadow: none;
}

.submit-btn {
  background-color: #bd2624;
  color: white;
}

.submit-btn:hover {
  background-color: maroon;
  color: white;
}

/* Client Component CSS */
.client-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20vh 0;
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;
}

.client-img {
  overflow: hidden;
  aspect-ratio: 3/2;
  width: 22vh;
  object-fit: contain;
  margin-left: 1vh;
}

.client-card {
  width: 80vh;
  height: 15vh;
}

/* Skeleton Loading CSS */
.skeleton-text,
.skeleton-image {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

/* Project Details Component CSS */
.project-container {
  position: relative;
  padding-bottom: 20vh;
  width: 100%;
  font-weight: bold;
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.8)), url('./images/projects.jpg');
  color: black;
}

.projects {
  position: relative;
  font-weight: bold;
  width: 40%;
  top: 15vh;
  padding: 30px 30px;
}

.project-detail {
  border-bottom: 2px solid black;
  padding: 8px 0;
}

/* Consultant Component CSS */
.consultant-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding: 160px 0;
  align-items: center;
  justify-content: center;
  background-color: #EEEEEE;
}

.careers-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 140px 20px;
  align-items: center;
  justify-content: center;
}

/* Customer ChatBot */
.closeBot {
  position: absolute;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
}

.fixed-container {
  position: fixed;
  bottom: 3vh;
  right: 3vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
}

.botButton {
  border: none;
  cursor: pointer;
  border-radius: 15px;
  font-size: 6vh;
  color: white;
  background-color: #bd2624;
  padding: 8px 18px;
}

.bubble-message {
  position: absolute;
  top: -20px;
  left: -150px;
  --r: 10px;
  --t: 20px;
  padding: calc(2*var(--r)/2);
  -webkit-mask:
    radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r)/-2) calc(var(--r)/-2) padding-box,
    radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0/var(--r) var(--r) space padding-box;
  background-color: #D3D3D3;
  color: black;
  --_d: 100%;
  border-right: var(--t) solid #0000;
  margin-left: var(--t);
  font-size: 16px;
}


.chat-container {
  position: fixed;
  height: 75vh;
  width: 50vh;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: white;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth; 
  box-shadow: 20px 20px 20px 20px rgba(0, 0, 0, 0.7);
}


.bot-header {
  position: sticky;
  padding: 15px 0;
  background-color: #bd2624;
  color: white;
}

.message-container {
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 4px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.message-container::-webkit-scrollbar {
  display: none;
}

.message {
  max-width: 70%;
  padding: 8px 12px;
  border-radius: 8px;
}

.user-message {
  margin-right: 1vh;
  margin-top: 1vh;
  background-color: #007bff;
  color: #fff;
  align-self: flex-end;
}

.other-message {
  margin-left: 1vh;
  margin-top: 1vh;
  background-color: #E0E0E0;
  color: #333;
  align-self: flex-start;
  font-size: 14px;
}

.menu-option {
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 70%;
  margin-left: 1vh;
  margin-top: 1vh;
  background-color: #0452a7;
  color: white;
  align-self: flex-start;
  font-size: 14px;
}

.menu-option:hover {
  background-color: #013671;
}

.menu-option:focus {
  outline: none;
}

.chat-box {
  display: flex;
  align-items: flex-end;
  padding: 10px;
  margin-top: auto;
}

.input-container {
  display: flex;
  width: 100%;
}

.input-container input {
  flex: 1;
  border: none;
  margin-right: 10px;
  outline: none;
}

.input-container button {
  display: none;
  color: white;
  background-color: green;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.typing-animation {
  display: flex;
  align-items: center;
  font-size: 6vh;
}

.dot {
  animation: typing 2s infinite;
  margin-left: 4px;
  opacity: 0;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



@media only screen and (max-width: 1200px) {
  .logobox {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 15%;
    transform: none;
    z-index: 1000;
    color: #bd2624;
  }

  .contactbutton {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    transform: none;
    z-index: 1000;
    color: #bd2624;
  }

  .container-fluid {
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    font-size: 16px;
    padding-bottom: 10px;
  }

  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -8px;
  }

  .brand-name {
    font-size: 24px;
  }

  .first {
    justify-content: center;
    text-align: center;
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 10vh;
    justify-content: left;
    margin-left: none;
    margin-right: none;
    padding: 50px 20px;
    background-color: white;
  }

  .application {
    display: flex;
    flex-direction: column;
    border: none;
    gap: 5vh;
  }

  .header1 {
    margin-left: 0;
    font-size: 50px;
    padding: 0 3vh;
  }

  .application-header {
    margin-left: 0;
    padding: 0 2vh;
  }

  .icon-container {
    background-color: #0452a7;
    height: max-content;
    padding: 10px 0;
  }

  .icon {
    display: flex;
    flex-direction: column;
  }

  .footer {
    height: auto;
    padding: 20px;
  }

  .details {
    flex-direction: column;
    gap: 2vh;
    font-size: 3vh;
  }

  .service-container {
    gap: 20px;
    background-color: #EEEEEE;
  }

  .service-card {
    width: 40rem;
    height: 22rem;
  }

  .client-container {
    padding: 20vh 0;
    flex-direction: column;
    gap: 0;
    background-color: #EEEEEE;
  }

  .client-card {
    gap: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 3vh 0;
  }

  .client-img {
    overflow: hidden;
    aspect-ratio: 3/2;
    width: 22vh;
    object-fit: contain;
    margin-left: 0;
  }

  .consultant-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0;
    padding: 160px 0;
    background-color: #EEEEEE;
  }

  .form-container {
    margin: 0 auto;
    background-color: white;
    padding: 10vh 5vh;
    width: 90%;
    box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.5);
  }

  .contactbutton {
    display: none;
  }

  .projects {
    width: 100%;
    padding: 30px 60px;
  }

  .contactusbutton {
    padding: 5px 15px;
    margin-top: 1vh;
  }

  .botButton {
    font-size: 5vh;
  }

  .chat-container {
    position: fixed;
    height: 60vh;
    width: 40vh;
    bottom: 0;
    z-index: 9999;
    background-color: white;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    border: 1px solid black;
  }


}